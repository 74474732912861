const TandC = () => {
    return <div>
        <h1>Terms and Conditions</h1>
<ol>
<li>
<p>
<b>Acceptance of Terms</b><br/>
By accessing and using the Fickatal app, you agree to be bound by these Terms and Conditions.
</p>
</li>

<li>
<p>
<b>
User Eligibility</b><br/>
You must be at least 15 years old to use the Fickatal app. By using the app, you represent that you meet the eligibility criteria.
</p>
</li>

<li>
<p>
<b> User Accounts</b><br/>
You may be required to create a user account or verify your identity to access certain features of the app. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
</p>
</li>

<li>
<p>
<b> User Conduct</b><br/>
You agree to use the Fickatal app in compliance with applicable laws and regulations. You must not engage in any activity that may disrupt or interfere with the app's functioning or violate the rights of other users.
</p>
</li>
<li>
<p>
<b> Content Ownership</b><br/>
By submitting content, you grant Fickatal a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute the content for the purpose of operating and promoting the app.
</p>
</li>

<li>
<p>
<b> Privacy and Data Collection</b><br/>
Fickatal collects and processes user data in accordance with its Privacy Policy. By using the app, you consent to the collection, storage, and use of your personal information as described in the Privacy Policy.
</p>
</li>

<li>
<p>
<b> Intellectual Property Rights</b><br/>
All intellectual property rights related to the Fickatal app, including but not limited to trademarks, logos, and software, are owned by Fickatal or its licensors. You agree not to use or reproduce any of these materials without prior written permission.
</p>
</li>


<li>
<p>
<b> Limitation of Liability</b><br/>
Fickatal shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of or in connection with the use of the app.
</p>
</li>

<li>
<p>
<b>Modifications and Termination</b><br/>
Fickatal reserves the right to modify or terminate the app or these Terms and Conditions at any time, without prior notice. Continued use of the app after any modifications constitutes your acceptance of the revised Terms and Conditions.
</p>
</li>
</ol>
    </div>
}

export default TandC;