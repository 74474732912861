import "./privacy_policy.css";

const PrivacyPolicy = () => {
 return <div>
    <h1>Privacy Policy for Fickatal</h1> 
		<p> 
		<h3>Effective Date: 25/06/2023</h3>
 
This Privacy Policy outlines how Fickatal ("we" or "our") collects, uses, discloses, and protects the personal information of users ("you" or "users") of the Fickatal mobile application ("the App").<br/>
 <br/>
 <b>1. Information We Collect </b><br/>
 <br/>
We may collect the following types of personal information from users: <br/>
 <br/>
1.1. Name: We collect your name to personalize your experience and facilitate communication. <br/>
 <br/>
1.2. Mobile Number: We collect your mobile number to enable account verification, secure access to the App, and send important notifications. <br/>
 <br/>
1.3. Postal Pin Code: We collect your postal pin code to provide you with localized information and improve your hyperlocal experience within the App. <br/>
 <br/>
1.4. Aadhaar Verification: To ensure the authenticity of users, we may verify your identity using Aadhaar. However, we do not store your Aadhaar number information. <br/>
 <br/>
 <b>2. Use of Personal Information </b><br/>
 <br/>
We may use the collected personal information for the following purposes: <br/>
 <br/>
2.1. Account Management: To create and manage your Fickatal account, including user authentication, profile setup, and personalized services. <br/>
 <br/>
2.2. Communication: To communicate with you regarding important updates, service-related announcements, and other relevant information. <br/>
 <br/>
2.3. Hyperlocal Experience: To enhance your experience within the App by providing localized content, recommendations, and relevant features based on your postal pin code. <br/>
 <br/>
2.4. Improving Services: To analyze user behavior, trends, and preferences to improve our services, features, and user interface. <br/>
 <br/>
 <b> 3. Data Sharing and Disclosure </b><br/>
 <br/>
We may share your personal information in the following circumstances: <br/>
 <br/>
3.1. With Elected Representatives and Appointed Officials: In order to facilitate communication between users and elected representatives/appointed officials, we may share certain user information on a need-to-know basis. <br/>
 <br/>
3.2. Service Providers: We may engage third-party service providers to perform various functions on our behalf, such as data storage, verification services, and analytics. These service providers have access to personal information only to the extent necessary to perform their services and are obligated to maintain its confidentiality. <br/>
 <br/>
3.3. Legal Requirements: We may disclose personal information if required by law, regulation, legal process, or governmental request. <br/>
 <br/>
 <b>4. Data Security </b><br/>
 <br/>
We implement appropriate security measures to protect your personal information and maintain its confidentiality. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security. <br/>
 <br/>
 <b>5. Children's Privacy</b> <br/>
 <br/>
Fickatal is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will take steps to delete it as soon as possible. <br/>
 <br/>
 <b> 6. Changes to the Privacy Policy </b><br/>
 <br/>
We may update this Privacy Policy from time to time. Any changes will be effective upon posting the revised policy in the App. We encourage you to review this Privacy Policy periodically for any updates. <br/>
 <br/>
 <b>7. Contact Us </b><br/>
 <br/>
If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at contact@fickatal.com . <br/>
 <br/>
By using the Fickatal App, you signify your acceptance of this Privacy Policy. If you do not agree with this policy, please do not use the App.</p><br/>

 </div>

}

export default PrivacyPolicy;