import './main.css';
import React, { Component } from 'react';
class  Main extends Component {
	render() {
  return (
    <div className="home-container">
			<div className="home-header">
				<img src="/fickatal_logo.png" alt="LOGO" className="header-logo" />
      </div>
        <div className="home-content">
          <div className="content-text">
            <div className="jaansay-logo-container">
              <div className="jaansay-header-text">
								Welcome to Ficka
                <span className="logo-color">tal</span>
              </div>
            </div>
          <h3>Connect - Listen - Engage - Grow</h3>
          <h4 className="primary-color">
						Empowering Hyperlocal Connections and Collaboration
					</h4>
					<p>
						At Fickatal, we believe in the power of community. Our mission is to revolutionize hyperlocal engagement, connect businesses, elected representatives, appointed officials, and local associations, and foster vibrant local ecosystems. With Fickatal, you can unleash the true potential of your hyperlocal community and drive positive change.
					</p>
					<div>
					<h4 className="primary-color">Why Fickatal?</h4>
					<ul className="why-fickatal-list">
						<li>
							<strong className="primary-color">Connect</strong>: Forge meaningful connections with local businesses, elected representatives, and community members, building a strong network within your hyperlocal ecosystem.
						</li>
						<li>
							<strong className="primary-color">Collaborate</strong>: Foster collaboration and partnerships with fellow businesses, amplify your collective impact, and unlock growth opportunities for everyone involved.
						</li>
						<li>
							<strong className="primary-color">Engage</strong>: Engage with your community, gather valuable insights, and make data-driven decisions to better serve your customers and community members.
						</li>
						<li>
							<strong className="primary-color">Stay Informed</strong>: Never miss out on important neighborhood news, events, and opportunities. Stay connected and informed about what's happening in your hyperlocal community.
						</li>
					</ul>
					</div>
					<div>
					<h4 className="primary-color">Features and Benefits</h4>
					<ul className="why-fickatal-list">
						<li>
							<strong className="primary-color">Hyperlocal Hub</strong>: A centralized platform that brings businesses, elected representatives, officials, and community members together, fostering collaboration, communication, and growth.
						</li>
						<li>
							<strong className="primary-color">Business Networking</strong>: Connect with local businesses, explore collaboration opportunities, and amplify your reach within the hyperlocal market.
						</li>
						<li>
							<strong className="primary-color">Community Engagement</strong>: Engage with community members, gather feedback, and build a strong relationship based on trust and shared interests.
						</li>
						<li>
							<strong className="primary-color">Events and News</strong>: Stay updated on neighborhood news, events, and local initiatives, ensuring you never miss out on exciting opportunities.
						</li>
						<li>
							<strong className="primary-color">Data-driven Insights</strong>: Leverage data analytics and insights to make informed decisions, optimize your business strategies, and enhance customer experiences.
						</li>
						<li>
							<strong className="primary-color">Digital Transformation</strong>: Embrace the digital revolution and leverage Fickatal's platform to establish a strong online presence, connecting with your target audience effectively.
						</li>
					</ul>
					</div>
					<div>
					<h4 className="primary-color">Join Fickatal and Unleash the Power of Your Hyperlocal Community</h4>

					Whether you are a business owner, elected representative, appointed official, or a community member passionate about driving change, Fickatal is your platform for hyperlocal success. Join us today and be a part of the hyperlocal movement that empowers, connects, and transforms.
					</div>
        </div>
      </div>
      <div className="footer">
           <p className="content-text-color">
            Get more information by contacting us on 
            <span  className="primary-color"> contact@fickatal.com </span> 
            or 
            <span className="primary-color"> +91 9980793399</span>
					</p>
      </div>
    </div>
 )};
}

export default Main;
