import logo from './logo.svg';
import './App.css';
import { Fragment } from "react";
import Main from './screens/main/main';
import Mobile  from './screens/mobile/mobile';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from './screens/about_us/about_us';
import PrivacyPolicy from './screens/privacy_policy/privacy_policy';
import TandC from './screens/terms_and_conditions/terms_and_conditions';
import LegacyApp from './screens/legacy_app/legacy_app';

function App() {
  return (
    <Fragment>
    <BrowserRouter>
      <Routes>
          <Route exact path='/' element={<Main />} />
          <Route exact path='/app.html' element={<LegacyApp />} />
          <Route exact path='/mobile/:pageName/:index/:itemGuid' element={<Mobile />} />
          <Route exact path='/about_us' element={<AboutUs />} />
          <Route exact path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route exact path='/terms_and_conditions' element={<TandC />} />
      </Routes>
    </BrowserRouter>
    </Fragment>
  );
}

export default App;
