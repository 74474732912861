import "./about_us.css";

const AboutUs = () => {
    return <div className="center_align">
        <p>
<b className="highlighted_text">Welcome to Fickatal</b> - India's first-of-its-kind platform built exclusively for communities. We believe that the power of collective opinions can bring about real change and empower local voices. Unlike other social networks, Fickatal focuses on hyperlocal communities, providing a platform where verified individuals within the same community can express their views.
</p>

<p>
Our unique approach ensures that the opinions shared on Fickatal come from individuals who are verified members of the community. This means you can trust that the voices you hear truly represent the thoughts and concerns of your neighbors and fellow community members.
</p>

<p>
The strength of Fickatal lies in the fact that when a significant number of individuals within a community raise their concerns or express their opinions on a particular matter, it compels local leaders, elected representatives, and appointed officials to take action. By collating these collective voices, we empower the community to drive positive change and make a difference.
</p>

<p>
While other social networks like Twitter or Facebook provide a global platform, they often lack the ability to capture the nuances and specific needs of hyperlocal communities. Fickatal bridges this gap by focusing solely on the concerns and aspirations of your neighborhood, making it a powerful tool for community engagement and decision-making.
</p>

<p>
<b className="highlighted_text">Here's how it works:</b> Communities can raise their neighborhood concerns, discuss issues related to their society, and ask others to vote or express their opinions. Fickatal serves as the medium to collate these valuable insights and present them in a meaningful way. This collective data helps drive informed decisions, fosters community collaboration, and strengthens the bond between individuals, businesses, and elected representatives.
</p>
<p>
Join Fickatal today and become part of a community-driven movement. Together, we can create a platform where your voice matters, where opinions are heard, and where positive change is made possible.
</p>
<p>
<b className="highlighted_text">Empower your community with Fickatal - your voice, your community, your power.</b>
</p>
    </div>
}

export default AboutUs;